import { InteractionType } from "@azure/msal-browser"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import React, { useEffect, useState } from "react"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { Loading } from "../../../Utils/Loading"
import { loginRequest } from "../../security/authConfig"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import LoadingData from "../../common/graphichs/LoadingData"
import { navigate } from "gatsby"
import { toast } from "react-toastify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, patchApi, postApi } from "../../../Utils/Api.utils"
import { Employment } from "../../../Utils/Entities"
import { MyEventsType } from "../../mypagecomponents/event/EventType"
import { Controller, useForm } from "react-hook-form"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import nb from "date-fns/locale/nb"
import { ErrorMessage } from "@hookform/error-message"
import { m } from "framer-motion"
import { typePrivatepractitioner, workcategory } from "../../../Utils/Maps"

registerLocale("nb", nb)

type FormValues = Employment

const ConvertToBiEmploymentContent = props => {
  const prevEmploymentData = props?.prevEmploymentData
  const employmentCategory = props?.employmentCategory
  const queryClient = useQueryClient()

  console.log(prevEmploymentData)
  console.log(employmentCategory)
  console.log(props)

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const [AStedFri, setAStedFri] = useState<boolean>(false)
  const [aGiverFri, setAGiverFri] = useState<boolean>(false)
  const [empName, setEmpName] = useState<string>()
  const [placeName, setPlaceName] = useState<string>()
  const [posName, setPosName] = useState<string>()

  const [employment, setEmployment] = useState<Employment>()

  const [partTimePosition, setPartTimePosition] = useState<number>(0)
  const [startDate, setStartDate] = useState<Date>(prevEmploymentData?.endDate)

  const [showPos, setShowPos] = useState<boolean>(false)
  const [isPAlt, setIsPAlt] = useState<boolean>(false)

  const [roleName, setRoleName] = useState<string>()

  const [invalidDate, setInvalidDate] = useState<boolean>(false)
  const [invalidpartTimePosition, setInvalidpartTimePosition] =
    useState<boolean>(false)
  const [response, setResponse] = useState<MyEventsType | undefined>(undefined)

  const [timer, setTimer] = useState<number>(2500)
  const [showLoadingData, setShowLoadingData] = useState(false)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setResponse(data)
        if (data?.eventRegistration?.length > 0) setTimer(6000)
      },
    }
  )

  useEffect(() => {
    const getEmployment = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Employments/${prevEmploymentData?.id}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setRoleName("Biarbeidsgiver")
          setEmployment(response.data)
          if (response.data.employer != undefined) {
            fetchApi(
              `${process.env.GATSBY_API_URL}/Accounts/${response.data.employer}`,
              account,
              inProgress,
              instance
            ).then(r => {
              setEmpName(r.data.name)
              if (response.data.position != undefined) {
                r.data.tariff.positions.forEach(p => {
                  if (p.id === response.data.position) setPosName(p.jobTitle)
                })
                setShowPos(true)
              } else {
                setShowPos(false)
                setIsPAlt(true)
              }
            })
          } else setAGiverFri(true)
          if (response.data.workPlace != undefined)
            fetchApi(
              `${process.env.GATSBY_API_URL}/Accounts/${response.data.workPlace}`,
              account,
              inProgress,
              instance
            ).then(r => {
              setPlaceName(r.data.name)
            })
          else setAStedFri(true)
          reset(response.data)
        })
        .catch(error => {})
    }
    getEmployment()
  }, [])

  const mutationUpdateWork = useMutation(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${prevEmploymentData?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        console.log(prevEmploymentData)
        if (employmentCategory === 292460000) {
          const NewEmploymentAsEmployee = {
            conatct: prevEmploymentData?.contact,
            employer: prevEmploymentData?.employer,
            employerAlternative: prevEmploymentData?.employerAlternative,
            partTimePosition: partTimePosition,
            position: prevEmploymentData?.position,
            positionAlternative: prevEmploymentData?.positionAlternative,
            role: 125600001,
            startDate: startDate,
            typePrivatePractitioner:
              prevEmploymentData?.typePrivatePractitioner,
            workPlace: prevEmploymentData?.workPlace,
            workPlaceAlternative: prevEmploymentData?.workPlaceAlternative,
            workPlaceAlternativeAddress:
              prevEmploymentData?.workPlaceAlternativeAddress,
            workPlaceTelephone: prevEmploymentData?.workPlaceTelephone,
          }
          mutationNewWork.mutate(NewEmploymentAsEmployee)
        } else if (
          employmentCategory === 292460001 ||
          employmentCategory === 292460002
        ) {
          const NewEmploymentAsIndependent = {
            agreementspecialist: prevEmploymentData?.agreementspecialist,
            clientAddress: prevEmploymentData?.clientAddress,
            clientId: prevEmploymentData?.clientId,
            clientOrgNo: prevEmploymentData?.clientOrgNo,
            clientZipCodeId: prevEmploymentData?.clientZipCodeId,
            clientsName: prevEmploymentData?.clientsName,
            contact: prevEmploymentData?.contact,
            employer: prevEmploymentData?.employer,
            operatingGrants: prevEmploymentData?.operatingGrants,
            partTimePosition: partTimePosition,
            rightOfReimbursment: prevEmploymentData?.rightOfReimbursment,
            role: 125600001,
            startDate: startDate,
            typePrivatePractitioner:
              prevEmploymentData?.typePrivatePractitioner,
            workcategory: prevEmploymentData?.workcategory,
          }
          mutationNewWork.mutate(NewEmploymentAsIndependent)
        }
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const mutationNewWork = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: data => {
        mutationUpdateEmployment.mutate()
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const mutationUpdateEmployment = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        {
          professionallyActiveStatus: 292460002,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
        }
        window.setTimeout(() => {
          navigate("/app/profil/arbeidsforhold")
        }, timer)
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const patchWork = async (data, event) => {
    // console.log(prevEmploymentData)
    event.preventDefault()
    setShowLoadingData(true)
    mutationUpdateWork.mutate(prevEmploymentData)
  }

  return showLoadingData ? (
    <LoadingData subject="Oppretter..." />
  ) : (
    <MyPageLayout2 props="Endre til biarbeidsforhold">
      <div className="">
        <form
          method="PATCH"
          onSubmit={handleSubmit(patchWork)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <h2 className="flex justify-center text-xl mob:text-center font-bold text-meny-700 ">
                  Biarbeidsforhold
                </h2>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Rolle:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="role"
                      id="role"
                      readOnly={true}
                      key="role"
                      defaultValue={roleName}
                      className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    ></input>
                  </div>
                </div>

                {aGiverFri ? (
                  <div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employerAlternative"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers navn:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="employerAlternative"
                          id="employerAlternative"
                          readOnly={true}
                          defaultValue={employment?.employerAlternative}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workTelephone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers telefon:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          readOnly={true}
                          defaultValue={employment?.workTelephone}
                          name="workTelephone"
                          id="workTelephone"
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="employerAlternativeAddress"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidsgivers adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          rows={3}
                          id="employerAlternativeAddress"
                          readOnly={true}
                          defaultValue={employment?.employerAlternativeAddress}
                          // required
                          name="employerAlternativeAddress"
                          className="bg-gray-200 form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="employer"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsgiver:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        name="employer"
                        id="employer"
                        key="employer"
                        readOnly={true}
                        defaultValue={empName}
                        className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                )}

                {employmentCategory === 292460000 ? (
                  <>
                    {AStedFri ? (
                      <div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                          <label
                            htmlFor="workPlaceAlternative"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Arbeidsstedets navn:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              name="workPlaceAlternative"
                              id="workPlaceAlternative"
                              readOnly={true}
                              defaultValue={employment?.workPlaceAlternative}
                              className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                          <label
                            htmlFor="workPlaceTelephone"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Arbeidsstedets telefon:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              readOnly={true}
                              name="workPlaceTelephone"
                              id="workPlaceTelephone"
                              defaultValue={employment?.workPlaceTelephone}
                              className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                          <label
                            htmlFor="workPlaceAlternativeAddress"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Arbeidsstedets adresse:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea
                              rows={3}
                              id="workPlaceAlternativeAddress"
                              readOnly={true}
                              defaultValue={
                                employment?.workPlaceAlternativeAddress
                              }
                              // required
                              name="workPlaceAlternativeAddress"
                              className="bg-gray-200 form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="workPlace"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Arbeidssted:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="workPlace"
                            id="workPlace"
                            key="workPlace"
                            readOnly={true}
                            defaultValue={placeName}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}

                    {showPos && (
                      <div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                          <label
                            htmlFor="position"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Stilling:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              name="position"
                              id="position"
                              key="position"
                              readOnly={true}
                              value={posName}
                              className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {isPAlt && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="workPlaceAlternative"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Stilling (fritekst):
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="positionAlternative"
                            id="positionAlternative"
                            readOnly={true}
                            defaultValue={employment?.positionAlternative}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="workcategory"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Arbeidskategori:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="workcategory"
                          id="workcategory"
                          readOnly={true}
                          defaultValue={workcategory.get(
                            employment?.workcategory
                          )}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="typePrivatePractitioner"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Type:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="typePrivatePractitioner"
                          id="typePrivatePractitioner"
                          readOnly={true}
                          defaultValue={typePrivatepractitioner.get(
                            employment?.typePrivatePractitioner
                          )}
                          className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    {employment?.clientsName != undefined && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="clientsName"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Oppdragsgiver:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="clientsName"
                            id="clientsName"
                            readOnly={true}
                            defaultValue={employment?.clientsName}
                            className="bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="partTimePosition"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Prosentvis stilling:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="partTimePosition"
                      id="partTimePosition"
                      onChange={e =>
                        setPartTimePosition(parseInt(e.target.value))
                      }
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Start dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <ReactDatePicker
                      required
                      locale="nb"
                      onChange={date => setStartDate(date)}
                      defaultValue={new Date(prevEmploymentData?.endDate)}
                      selected={
                        startDate === undefined
                          ? new Date()
                          : Date.parse(startDate)
                      }
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date(prevEmploymentData?.endDate)}
                      maxDate={(() => {
                        const endDate = new Date(prevEmploymentData?.endDate)
                        return new Date(
                          endDate.getFullYear(),
                          endDate.getMonth() + 2,
                          endDate.getDate()
                        )
                      })()}
                      className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-5 flex justify-end">
                <button
                  type="button"
                  onClick={() => history.back()}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Tilbake
                </button>
                <button
                  type="submit"
                  disabled={
                    startDate === undefined ||
                    startDate === null ||
                    partTimePosition === 0 ||
                    partTimePosition > 50 ||
                    Number.isNaN(partTimePosition)
                  }
                  className="  ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700 disabled:bg-gray-300"
                >
                  Lagre
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </MyPageLayout2>
  )
}

const ConvertToBiEmployment = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const prevEmploymentData = location?.state?.prevEmploymentData
  const employmentCategory = location?.state?.employmentCategory
  // console.log(location.state)
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ConvertToBiEmploymentContent
        prevEmploymentData={prevEmploymentData}
        employmentCategory={employmentCategory}
      />
    </MsalAuthenticationTemplate>
  )
}

export default ConvertToBiEmployment
