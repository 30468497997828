import React, { useState } from "react"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { loginRequest } from "../../../security/authConfig"
import NewEmploymentToOrdinary from "../../../mypagecomponents/membership/applications/FlowToOrdinary/NewEmploymentToOrdinary"
import { ErrorComponent } from "../../../common/graphichs/ErrorComponent"
import { Loading } from "../../../../Utils/Loading"
import ExistingEmploymentToOrdinary from "../../../mypagecomponents/membership/applications/FlowToOrdinary/ExistingEmploymentToOrdinary"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import { MyEmployments } from "../../../../Utils/Entities"
import useApi from "../../../hooks/useApi"

function EmploymentToOrdinaryContent(props: { location: any }) {
  const [employerType, setEmployerType] = useState("")

  const employmentQuery = useApi<MyEmployments[]>("/Contacts/Employments")

  return (
    <MyPageLayout props="Arbeidsforhold">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 mb-12">
        <label
          htmlFor="employer"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Arbeidsforhold:
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            name="employer"
            id="employer"
            key="employer"
            onChange={e => setEmployerType(e.target.value)}
            value={employerType}
            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
          >
            <option selected value="">
              ---
            </option>
            <option value="new">Ny Arbeidsgiver</option>
            <option
              disabled={!checkIfEmploymentExists(employmentQuery.data)}
              value="existing"
            >
              Endre eksisterende arbeidsgiver
            </option>
          </select>
        </div>
      </div>
      {employerType === "new" && (
        <NewEmploymentToOrdinary location={props.location} />
      )}
      {employerType === "existing" && (
        <ExistingEmploymentToOrdinary employments={employmentQuery.data!} />
      )}
    </MyPageLayout>
  )
}

const checkIfEmploymentExists = (employments?: MyEmployments[]) => {
  if (!employments) {
    return false
  }

  return employments.length > 0
}

const EmploymentToOrdinary = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EmploymentToOrdinaryContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default EmploymentToOrdinary
