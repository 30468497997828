import {
  AcademicCapIcon,
  AnnotationIcon,
  BadgeCheckIcon,
  BellIcon,
  BookOpenIcon,
  BriefcaseIcon,
  CashIcon,
  ChatAlt2Icon,
  ClipboardIcon,
  CalendarIcon,
  OfficeBuildingIcon,
  ClockIcon,
  MailOpenIcon,
  MenuIcon,
  ReceiptRefundIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline'

export const memberShipTabs = [
  { name: "Persondata", current: true, show: true, id: 0 },
  { name: "Utdanning", current: false, show: true, id: 1 },
  { name: "Arbeidsforhold", current: false, show: true, id: 2 },
  { name: "Send inn", current: false, show: true, id: 3 },
]

export const incidentTabs = [
  { name: "Sak", id: "incident", current: true },
  { name: "Meldinger", id: "message", current: false },
]

export const landingPageActions = [
  {
    icon: ChatAlt2Icon,
    name: 'Min Profil',
    href: 'profil',
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    icon: ClipboardIcon,
    name: 'Medlemskap',
    href: 'medlemskap/nymedlemskap',
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },
  {
    icon: OfficeBuildingIcon,
    name: 'Arbeidsforhold',
    href: 'profil/arbeidsforhold',
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
  },
  {
    icon: BookOpenIcon,
    name: 'Mine kurs',
    href: 'kurs/minekurs',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    icon: CalendarIcon,
    name: 'Kurskalender',
    href: 'kurs/kurskalender',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    icon: AcademicCapIcon,
    name: 'Spesialistutdanning',
    href: 'kurs/specialityeducation/utdanning',
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    icon: ChatAlt2Icon,
    name: 'Meldinger',
    href: 'dialog/minemeldinger',
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    icon: AcademicCapIcon,
    name: 'Mine saker',
    href: 'dialog/minesaker',
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
  },
  { icon: AnnotationIcon, name: 'Ny henvendelse', href: 'dialog/nysak', iconForeground: 'text-yellow-700', iconBackground: 'bg-yellow-50' },
]
