import { useAccount, useMsal } from "@azure/msal-react"
import { ErrorMessage } from "@hookform/error-message"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { fetchApi, patchApi, postApi } from "../../../../Utils/Api.utils"
import { FormValues } from "../../../../Utils/Types"
import LoadingData from "../../../common/graphichs/LoadingData"
import MyPageLayout from "../../../common/layouts/myPageLayout"
import Consent from "../../../mypagecomponents/contacinfo/Consent"

const EmptyGuid = "00000000-0000-0000-0000-000000000000"

const SendMembership = ({ location, educationData, documents, props }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [description, setDescription] = useState("")
  const [disableButton, setDisabled] = useState<boolean>(false)
  const [showLoadingData, setShowLoadingData] = useState<boolean>(false)
  const {
    register,
    formState: { errors },
  } = useForm<FormValues>()
  const queryClient = useQueryClient()

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {},
      onError: _error => {},
    }
  )

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Endringssøknad: Student til Ordinær",
          description: description,
          caseTypeCode: 16,
          caseOriginCode: 3,
          // Emne = Medlemskap --> Endring av medlemskapstype --> Student til ordinær
          subjectId: "8ae31fc1-f49d-ec11-b400-000d3aadca3e",
          approveStatus: 778380001,
          subscriptionType: 778380000,
          OrganizationId: process.env.GATSBY_ORG_GUID,
          ContineInsurence: location.state.consentLiabality,
          membershipCategory: "d44f2c76-b677-ec11-8d21-000d3aa9685b",
          workSituations: 778380000,
          status: 1,
          state: 0,
          membershipId: userInfoQuery.data.activeMemebershipId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userIncidentData"])
        if (documents.length > 0) {
          const formData = new FormData()
          Array.from(documents).forEach(file =>
            formData.append("arrayOfFiles", file)
          )

          formData.append("EntityId", data)
          formData.append("EntityName", "incident")

          mutationUploadDocumets.mutate(formData)
        }

        const emplyomentData = location.this.state.emplyomentData
        // emplyomentData.incidentId = data

        if (
          emplyomentData.workPlace === EmptyGuid ||
          emplyomentData.workPlace === ""
        )
          emplyomentData.workPlace = null

        if (
          emplyomentData.employer === EmptyGuid ||
          emplyomentData.employer === ""
        )
          emplyomentData.employer = null

        console.log(emplyomentData)
        // mutationUpdateEmployer.mutate(emplyomentData)
        //patchEmployer.mutate()
      },

      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {
        navigate("/app/dialog/minesaker")
      },
    }
  )

  // const mutationUpdateEmployer = useMutation(
  //   data => {
  //     return patchApi(
  //       process.env.GATSBY_API_URL + "/Employments",
  //       data,
  //       account,
  //       inProgress,
  //       instance
  //     ).then(res => res.data)
  //   },
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["userIncidentData"])
  //       toast.success(
  //         "Saken er opprettet! Du vil nå bli videreført til dine saker.",
  //         {
  //           position: "top-center",
  //           autoClose: 5000,
  //         }
  //       )
  //       setTimeout(() => {
  //         navigate("/app/dialog/minesaker")
  //       }, 5000)
  //     },
  //     onError: _error => {},
  //     onSettled: () => {},
  //   }
  // )

  const mutationUploadDocumets = useMutation(
    formData => {
      return postApi(
        process.env.GATSBY_API_URL + "/Documents",
        formData,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: () => {},
      onError: _error => {},
      onSettled: () => {},
    }
  )

  const sendInnSchema = () => {
    setDisabled(true)
    setShowLoadingData(true)
    mutationCreateIncident.mutate()
  }

  return showLoadingData ? (
    // // "Laster inn..."
    <LoadingData subject="Oppretter..." />
  ) : userInfoQuery.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props={"Endringssøknad"}>
      <div>
        <div>
          <div>
            <p className="mt-1 text-lg text-gray-900">
              Ordinært medlemskap i Norsk psykologforening er betinget av
              godkjenning (Autorisasjon/Lisens) som psykolog i Norge iflg. Lov
              om Helsepersonell av 16. juni 2000. Godkjenning foretas av
              Helsedirektoratet.
            </p>
          </div>
        </div>
        <br />
        <br />

        <Consent />

        <br />
        <br />
        <div className="sm:col-span-6">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Kommentar
          </label>
          <p className="mt-2 text-sm text-gray-600">Skriv noen ord om saken.</p>
          <div className="mt-1">
            <textarea
              {...register("description", {
                minLength: {
                  value: 10,
                  message: "Minimum tegn er 10",
                },
                maxLength: {
                  value: 2000,
                  message: "Maks tegn er 2000",
                },
              })}
              id="description"
              name="description"
              onChange={e => setDescription(e.target.value)}
              rows={3}
              className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              defaultValue={""}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              render={({ message }) => (
                <p className="text-sm text-red-600">{message}</p>
              )}
            />
          </div>
        </div>

        <div className="pt-5 flex justify-end">
          <button
            type="button"
            disabled={disableButton}
            onClick={sendInnSchema}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
          >
            Send søknaden
          </button>
        </div>
      </div>
    </MyPageLayout>
  )
}

export default SendMembership
