import React, { useEffect, useState } from "react"
// import { Disclosure } from "@headlessui/react"
import { navigate } from "gatsby"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { fetchApi, patchApi } from "../../../Utils/Api.utils"
import MyPageLayout from "../../common/layouts/myPageLayout"
import HeaderText from "../../common/text/Header"
import { loginRequest } from "../../security/authConfig"
import { useMutation, useQuery } from "react-query"
import { Loading } from "../../../Utils/Loading"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import Modal from "../../common/modal/Modal"
import {
  employmentTypes,
  retiredEmploymentTypes,
} from "../../../Utils/optionSet"
import { RadioGroupComponent } from "../../common/radioGroup/RadioGroup"
import MainArbeidsforhold from "../../mypagecomponents/work/MainArbeidsforhold"
import Arbeidsforhold from "../../mypagecomponents/work/Arbeidsforhold"
import { toast } from "react-toastify"
import LoadingData from "../../common/graphichs/LoadingData"
import { professionallyActiveStatus } from "../../../Utils/Maps"
import { MyEmployments } from "../../../Utils/Types"
import IncomingWork from "../../mypagecomponents/work/IncomingWork"

const EmploymentContent = () => {
  const [showModal, setShowModal] = useState(false)
  const today = new Date()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [activeEmployment, setActiveEmployment] = useState<boolean>(false)
  const [activeEmploymentId, setActiveEmploymentId] = useState<string>("")
  const [employmentType, setEmploymentType] = useState<number>(0)
  const [newEmploymentType, setNewEmploymentType] = useState<number>(0)
  const [modalValues, setModalValues] = useState({})
  const [showLoadingData, setShowLoadingData] = useState(false)
  const [timer, setTimer] = useState<number>(2500)
  const [activeMainWork, setActiveMainWork] = useState<MyEmployments>()
  const [incomingMainWork, setIncomingMainWork] = useState<MyEmployments>()
  const [hasIncomingWork, setHasIncomingWork] = useState<boolean>(false)

  const [sMedlem, setSMedlem] = useState<boolean>(false)
  const [membershipError, setMembershipError] = useState<boolean>(false)
  const [retired, setRetired] = useState<boolean>(false)

  const userInfoQuery = useQuery(
    "userInfo",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setEmploymentType(data?.professionallyActiveStatus)
        setNewEmploymentType(data?.professionallyActiveStatus)
        setRetired(data?.retired)
      },
      onError: _error => {},
    }
  )

  const getSub = useQuery(
    "getSub",
    () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        response?.data.forEach(sub => {
          if (
            sub?.parentOrganizationUnit?.name == "Norsk Psykologforening" &&
            sub?.subscriptionTypeName == "Medlemskap" &&
            sub?.stateCode == 0 &&
            sub?.membershipCategory?.name == "Student"
          ) {
            setSMedlem(true)
          }
        })
      }),
    {
      onSuccess: data => {},
      onError: _error => {
        setMembershipError(true)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
    }
  )

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: userInfoQuery.isSuccess,
      onSuccess: data => {
        data?.find(work => {
          console.log(work)
          if (work.role == 125600000 && work.statuscode == 1) {
            setActiveEmployment(true)
            setActiveEmploymentId(work.id)
          }
        })
        const incomingWork = data?.filter(
          work => work?.role == 125600000 && work?.statuscode == 292460002
        )
        if (incomingWork.length > 0) {
          setHasIncomingWork(true)
          setIncomingMainWork(incomingWork)
        }
      },
      onError: _error => {},
    }
  )

  const mutationUpdateEmployment = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        {
          professionallyActiveStatus: newEmploymentType,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setShowLoadingData(false)
        window.setTimeout(() => {
          toast.success("Arbeidsforhold er oppdatert", {
            position: "top-center",
            autoClose: 6000,
          })
        }, timer)
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  console.log(retired)

  // const checkForActiveWork = () => {
  //   if (activeEmployment === true) {
  //     setModalValues(
  //       <>
  //         <div>
  //           <p className=" mb-2 ">
  //             Har du en ny arbeidsgiver eller har det skjedd endringer på dine
  //             registrerte arbeidsforhold?
  //           </p>
  //         </div>
  //         <div className="w-full flex flex-wrap flex-row mt-8 justify-evenly space-y-4">
  //           <button
  //             onClick={() => {
  //               setShowModal(false)
  //             }}
  //             className="mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
  //           >
  //             Lukk
  //           </button>
  //           <button
  //             onClick={() =>
  //               navigate("/app/profil/endrearbeid/", {
  //                 state: {
  //                   workId: activeEmploymentId,
  //                   goToNewEmploymentPage: true,
  //                 },
  //               })
  //             }
  //             className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
  //           >
  //             Nytt hovedarbeidsforhold
  //           </button>
  //           <button className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700">
  //             Endre arbeidsforhold
  //           </button>
  //         </div>
  //       </>
  //     )
  //     setShowModal(true)
  //   }
  //   // else {
  //   //   navigate("/app/profil/nyttarbeid/", {
  //   //     state: { role: 125600000 },
  //   //   })
  //   // }
  // }

  // console.log(newEmploymentType)

  useEffect(() => {
    //From yrkesaktiv to another
    if (employmentType === 292460000 && newEmploymentType !== 292460000) {
      setModalValues(
        <>
          <div>
            <p className=" text-center mb-2 ">Hva ønsker du å gjøre?</p>
          </div>
          <div className="w-full flex flex-wrap flex-row mt-8 justify-evenly space-y-4 ">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Lukk
            </button>
            {/* Ikke yrkesaktiv */}
            {newEmploymentType === 292460001 && (
              <button
                onClick={() =>
                  navigate(
                    "/app/medlemskap/applications/avsluttarbeidsforhold/"
                  )
                }
                className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Avslutt arbeidsforhold
              </button>
            )}
            {/* Arbeidsøkende */}
            {newEmploymentType === 292460002 && (
              <>
                <button
                  onClick={() =>
                    navigate("/app/profil/endrearbeid/", {
                      state: {
                        workId: activeEmploymentId,
                        goToConvertToBiEmploymentPage: true,
                      },
                    })
                  }
                  className=" w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Endre til biarbeidsforhold
                </button>
                <button
                  onClick={() =>
                    navigate("/app/profil/endrearbeid/", {
                      state: {
                        workId: activeEmploymentId,
                        professionallyActiveStatus: newEmploymentType,
                      },
                    })
                  }
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Avslutt hovedarbeidsforhold
                </button>
              </>
            )}
          </div>
        </>
      )
      setShowModal(true)
    } else if (
      employmentType === 292460002 &&
      newEmploymentType === 292460001
    ) {
      //From arbeidsøkende to ikke yrkesaktiv
      setModalValues(
        <>
          <div>
            <p className=" text-center mb-2 ">
              Hva ønsker du å gjøre med ditt eksisterende hovedarbeidsforhold??
            </p>
          </div>
          <div className="w-full flex flex-wrap flex-row mt-8 justify-evenly space-y-4 ">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="mt-4 w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Lukk
            </button>
            <button
              onClick={() =>
                navigate("/app/medlemskap/applications/avsluttarbeidsforhold/")
              }
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Avslutt arbeidsforhold
            </button>
          </div>
        </>
      )
      setShowModal(true)
    }
    //
    else if (
      employmentType !== 292460000 &&
      employmentType !== newEmploymentType
    ) {
      setModalValues(
        <>
          <div>
            <p className=" mb-2 ">
              Ønsker du å endre fra{" "}
              <span className="font-semibold">
                {professionallyActiveStatus.get(employmentType)}
              </span>{" "}
              til{" "}
              <span className="font-semibold">
                {professionallyActiveStatus.get(newEmploymentType)}
              </span>
            </p>
          </div>
          <div className="w-full flex flex-row justify-around mt-8">
            <button
              onClick={() => {
                setShowModal(false)
                setNewEmploymentType(employmentType)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-gray-200 shadow-sm text-md font-medium rounded-md text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
            >
              Nei
            </button>
            <button
              onClick={() => {
                navigate("/app/profil/nyttarbeid/", {
                  state: {
                    role:
                      newEmploymentType === 292460002 ? 125600001 : 125600000,
                    professionallyActiveStatus: newEmploymentType,
                  },
                })
                setShowModal(false)
              }}
              className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Ja
            </button>
          </div>
        </>
      )
      setShowModal(true)
    }
  }, [newEmploymentType])

  // console.log("EmploymentType " + employmentType)
  // console.log("newEmploymentType " + newEmploymentType)

  // console.log(activeMainWork?.privatePractitioners)

  console.log(incomingMainWork)
  console.log(hasIncomingWork)

  return showLoadingData || userInfoQuery.isLoading || getSub.isLoading ? (
    <LoadingData />
  ) : (
    <MyPageLayout props="Arbeidsforhold">
      {showModal && <Modal setModalOpen={setShowModal}>{modalValues}</Modal>}
      <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8 flex justify-center">
        {/* If something goes wrong with the membership check api, dont show any options */}
        {membershipError ? null : (
          <>
            {sMedlem && (
              <div>
                <h3 className="text-md leading-6 font-medium text-meny-700">
                  Du er registrert som studentmedlem. Studentmedlemmer kan ikke
                  ha hovedarbeidsforhold, eller være registrert som
                  arbeidssøkende / ikke yrkesaktive før endt utdanning.
                  Vennligst meld overgang til ordinært medlem, dersom du er
                  ferdig med utdanningen.{" "}
                  <button
                    className="underline"
                    onClick={() =>
                      navigate("/app/medlemskap/applications/studentordinaer")
                    }
                  >
                    Klikk her
                  </button>
                </h3>
              </div>
            )}
            {retired === true && sMedlem === false && (
              <div className="max-w-lg flex flex-col">
                <div>
                  <h3 className="text-md leading-6 font-medium text-meny-700">
                    Du er registrert som pensjonist. Pensjonister kan ha
                    hovedarbeidsforhold og/eller biarbeidsforhold, eller være
                    registrert som ikke yrkesaktive. For å registrere
                    arbeidsforhold, trykk på knapp for hhv. "Opprett
                    hovedarbeidsforhold" eller "Opprett biarbeidsforhold".
                    Dersom du er registrert med hovedarbeidsforhold, kan du
                    endre på dette ved å trykke på "Endre hovedarbeidsforhold".
                  </h3>
                </div>
                <div className="flex justify-center">
                  <RadioGroupComponent
                    label={"Velg et arbeidsforhold"}
                    value={employmentType}
                    onChange={setNewEmploymentType}
                    type={retiredEmploymentTypes}
                  />
                </div>
              </div>
            )}

            {retired === false && sMedlem === false && (
              <div className="max-w-lg">
                <RadioGroupComponent
                  label={"Velg et arbeidsforhold"}
                  value={employmentType}
                  onChange={setNewEmploymentType}
                  type={employmentTypes}
                />
              </div>
            )}
          </>
        )}
      </div>
      {sMedlem ? null : (
        <>
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <HeaderText text="Hovedarbeidsforhold" />
            {/* <AktiveArbeidsforhold active={true} /> */}
            <MainArbeidsforhold
              activeMainWork={activeMainWork}
              setActiveMainWork={setActiveMainWork}
            />
          </div>

          {hasIncomingWork && (
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
              <HeaderText text="Kommende hovedarbeidsforhold" />
              <IncomingWork incomingMainWork={incomingMainWork} />
            </div>
          )}

          <div className="flex flex-row justify-center space-x-4">
            {employmentType === 292460000 && (
              <div className="flex justify-center py-4">
                {/* <button
                  type="submit"
                  onClick={checkForActiveWork}
                  className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
                >
                  Endre hovedarbeidsforhold
                </button> */}
                <button
                  onClick={() =>
                    navigate("/app/profil/endrearbeid/", {
                      state: {
                        workId: activeEmploymentId,
                        goToNewEmploymentPage: true,
                      },
                    })
                  }
                  className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                >
                  Nytt hovedarbeidsforhold
                </button>
              </div>
            )}

            {employmentType !== 292460001 && (
              <>
                <div className="flex justify-center py-4">
                  <button className="w-fit ml-1 inline-flex justify-end py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700">
                    Endre arbeidsforhold
                  </button>
                </div>
                <div className="flex justify-center py-4">
                  <button
                    type="submit"
                    onClick={() =>
                      navigate("/app/profil/nyttarbeid/", {
                        state: { role: 125600001 },
                      })
                    }
                    className="ml-1 inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-600"
                  >
                    Opprett biarbeidsforhold
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <HeaderText text="Biarbeidsforhold" />
        {/* <InaktivArbeid active={false} /> */}
        <Arbeidsforhold />
      </div>
    </MyPageLayout>
  )
}
const Employments = ({ data }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EmploymentContent />
    </MsalAuthenticationTemplate>
  )
}

export default Employments
