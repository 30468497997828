import React, { useEffect, useState } from "react"
import { MyEmployments } from "../../../../../Utils/Entities"
import EmploymentCard from "./EmploymentCard"
import { useFieldArray, useForm, useWatch } from "react-hook-form"
import { FormValues, MyEmploymentsExtended } from "./FlowToOrdinaryTypes"
import { navigate } from "gatsby"
import { patchApi } from "../../../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { toast } from "react-toastify"

export type ExistingEmploymentToOrdinaryProps = {
  employments: MyEmployments[]
}

function ExistingEmploymentToOrdinary(
  props: ExistingEmploymentToOrdinaryProps
) {
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const employmentData = props.employments
    ?.filter(isEmploymentOngoing)
    .filter(isValidEmployment)
    .sort(sortEmploymentsByDate)
    .map(item => ({
      ...item,
      action: item.endDate ? "cancel" : "keep",
    })) as MyEmploymentsExtended[]

  const formController = useForm<FormValues>({
    defaultValues: { employments: employmentData },
  })

  const fieldController = useFieldArray({
    control: formController.control,
    name: "employments",
  })

  const watchForm = useWatch({
    control: formController.control,
    name: "employments",
  })

  useEffect(() => {
    const mainEmploymentCount = checkMainEmploymentCount(watchForm)

    if (mainEmploymentCount > 1) {
      setError("Du kan ikke ha flere hovedarbeidsforhold")
      setDisableButton(true)
      return
    }

    setError("")
    setDisableButton(false)
  }, [watchForm])

  const onSubmit = (data: FormValues) => {
    data.employments.map(work => {
      let body: Partial<MyEmployments> = {}
      switch (work.action) {
        case "keep":
          // nothing to do
          return
        case "convert":
          // convert to ordinary and set new position and positionPercentage
          body = {
            role: 125600000, // 125600000 = main employment
            position: work.position,
            partTimePosition: work.partTimePosition,
          }
          break
        case "cancel":
          // set enddate
          body = {
            endDate: work.endDate,
          }
          break
        default:
          // nothing to do
          return
      }

      patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${work.id}`,
        body,
        account,
        inProgress,
        instance
      )
    })
    toast.success("Arbeidsforhold oppdatert")

    window.setTimeout(() => {
      navigate("/app/medlemskap/applications/sendchange")
    }, 2500)
  }

  return (
    <div>
      <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
        <form onSubmit={formController.handleSubmit(onSubmit)}>
          {fieldController.fields?.map((work: MyEmployments, index) => {
            return (
              <div key={work?.id}>
                <EmploymentCard
                  work={work}
                  index={index}
                  formController={formController}
                />
              </div>
            )
          })}
          <div className="pt-5 flex justify-end">
            <button
              type="submit"
              disabled={disableButton}
              className="disabled:opacity-50 disabled:bg-meny-600 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
            >
              Neste
            </button>
          </div>
          <div className="pt-5 flex justify-end">
            <p className="text-red-600">{error}</p>
          </div>
        </form>
      </div>
    </div>
  )
}

const isValidEmployment = (work: MyEmployments) => {
  const isEmployerDefined =
    work.employer !== undefined || work.employerAlternative !== undefined

  return isEmployerDefined
}

const isEmploymentOngoing = (work: MyEmployments) => {
  const today = new Date()
  if (work?.endDate != undefined) {
    const endDate = new Date(work?.endDate)
    if (endDate.getTime() < today.getTime()) return false
  }
  return true
}

const sortEmploymentsByDate = (a, b) => {
  // If both a and b have no endDate, sort by startDate
  if (!a.endDate && !b.endDate) {
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  }

  // If only a has no endDate, a should come first
  if (!a.endDate) {
    return -1
  }

  // If only b has no endDate, b should come first
  if (!b.endDate) {
    return 1
  }

  // If both a and b have an endDate, sort by startDate
  return (
    new Date(b.startDate || "").getTime() -
    new Date(a.startDate || "").getTime()
  )
}

const checkMainEmploymentCount = (work: MyEmploymentsExtended[]) => {
  let mainEmploymentCount = 0

  work.map(work => {
    if (work.role == 125600000 || work.action === "convert") {
      mainEmploymentCount++
    }
  })

  return mainEmploymentCount
}

export default ExistingEmploymentToOrdinary
