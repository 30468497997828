import { useAccount, useMsal } from "@azure/msal-react"
import { Switch } from "@headlessui/react"
import { ErrorMessage } from "@hookform/error-message"
import nb from "date-fns/locale/nb"
import { navigate } from "gatsby"
import React, { useState } from "react"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Controller, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { fetchApi, postApi } from "../../../../../Utils/Api.utils"
import { Account, Employment } from "../../../../../Utils/Entities"
import LoadingData from "../../../../common/graphichs/LoadingData"
import { classNames } from "../../../../common/graphichs/misc"
import HelperText from "../../../../common/text/HelperText"
registerLocale("nb", nb)

type FormValues = Employment
const NewEmploymentToOrdinaryContent = ({ location }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>()
  const [company, setAccounts] = useState<Account[]>()
  const [allWorkPlaces, setAllWP] = useState<Account[]>()
  const [allEmp, setAllEmployers] = useState<Account[]>()
  const [parent, setParent] = useState<string>()
  const [position, setPos] = useState<string>()
  const [places, setWorkPlaces] = useState<any[]>()
  const [disableE, setdisableE] = useState<boolean>(false)
  const [disableP, setdisableP] = useState<boolean>(false)
  const [AStedFri, setAStedFri] = useState<boolean>(false)
  const [aGiverFri, setAGiverFri] = useState<boolean>(false)

  const [positions, setPositions] = useState<any[]>()
  const [showPos, setShowPos] = useState<boolean>(true)
  const [disablePos, setDisablePos] = useState<boolean>(false)
  const [isPAlt, setIsPAlt] = useState<boolean>(false)

  const [invalidDate, setInvalidDate] = useState<boolean>(false)

  const [enabled1, setEnabled1] = useState(false)
  const [enabled2, setEnabled2] = useState(false)
  const [enabled3, setEnabled3] = useState(false)
  const [selectedWrokLocation, setSelectedWrokLocation] = useState("")
  const [showLoadingData, setShowLoadingData] = useState(false)
  const [disableButton, setDisabled] = useState<boolean>(false)

  const [hideCheckboxPlace, setHideCheckPlace] = useState<boolean>(false)

  const EmptyGuid = ""

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  useQuery(
    "employers",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Employers`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAccounts(data)
        setAllEmployers(data)
      },
    }
  )

  useQuery(
    "workPlaces",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Workplaces`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setWorkPlaces(data)
        setAllWP(data)
      },
    }
  )

  const updateWorkPlace = e => {
    setShowPos(false)
    setIsPAlt(false)

    setParent(e.target.value)
    setAGiverFri(false)
    setdisableP(false)

    if (e.target.value != EmptyGuid) {
      // setAccountIdWorkPlace(e.target.value)
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${e.target.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.workPlaces?.length > 0) {
            setWorkPlaces(response.data.workPlaces)
            setAStedFri(false)
            setdisableP(false)
            setHideCheckPlace(false)
          } else {
            setAStedFri(true)
            setdisableP(true)
            setHideCheckPlace(true)
            setSelectedWrokLocation(EmptyGuid)
          }
          if (response.data.tariff?.positions?.length > 0) {
            setShowPos(true)
            setPositions(response.data.tariff?.positions)
          } else {
            setShowPos(false)
            setIsPAlt(true)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setAGiverFri(true)
      setWorkPlaces(allWorkPlaces)
      setIsPAlt(true)
    }
  }

  const updateEmployer = e => {
    setSelectedWrokLocation(e.target.value)
    setdisableE(false)
    setAStedFri(false)
    if (e.target.value != EmptyGuid) {
      // setAccountIdEmployer(e.target.value)
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${e.target.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.parentEmployerId != undefined) {
            setParent(response.data.parentEmployerId)
            // setdisableE(true)
            setAGiverFri(false)
          } else {
            setParent(EmptyGuid)
            // setdisableE(true)
            setAGiverFri(true)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setAStedFri(true)
      if (!enabled1) {
        setAccounts(allEmp)
        setdisableE(false)
      }
    }
  }

  const changePos = event => {
    setPos(event.target.value)
  }

  const showEmpAlt = () => {
    setEnabled1(!enabled1)

    if (!enabled1) {
      setParent(EmptyGuid)
      setAGiverFri(true)
      setdisableE(true)
      setIsPAlt(true)
      setDisablePos(true)
      setShowPos(false)
    } else {
      setAGiverFri(false)
      setdisableE(false)
    }
  }
  const showPlaceAlt = () => {
    setEnabled2(!enabled2)

    if (!enabled2) {
      setSelectedWrokLocation(EmptyGuid)
      setAStedFri(true)
      setdisableP(true)
      if (!enabled1) {
        setAccounts(allEmp)
        setdisableE(false)
        setAGiverFri(false)
      }
    } else {
      setAStedFri(false)
      setdisableP(false)
    }
  }
  const showPosAlt = () => {
    setEnabled3(!enabled3)

    if (!enabled3) {
      setIsPAlt(true)
      setDisablePos(true)
      // if (!enabled1) {
      //   setAccounts(allAccounts)
      //   setdisableE(false)
      //   setAGiverFri(false)
      // }
    } else {
      setIsPAlt(false)
      setDisablePos(false)
    }
  }

  const postWork = async (data, event) => {
    console.log(data.startDate)
    console.log(data.endDate)
    setInvalidDate(false)
    if (aGiverFri) data.employer = ""
    else {
      data.employerAlternative = ""
      data.employer = parent
    }

    if (AStedFri) data.workPlace = ""
    else data.workPlaceAlternative = ""

    if (isPAlt) data.position = ""
    else data.positionAlternative = ""

    data.role = 125600000

    if (data.endDate != null || data.endDate != undefined) {
      if (data.startDate >= data.endDate) {
        console.log("Datoen er ugyldig")
        setInvalidDate(true)
      } else {
        console.log("datoen er gyldig")
        setInvalidDate(false)
        setDisabled(true)
        setShowLoadingData(true)

        postApi(
          `${process.env.GATSBY_API_URL}/Employments`,
          data,
          account,
          inProgress,
          instance
        )
          .then(() => {
            window.setTimeout(() => {
              navigate("/app/medlemskap/applications/sendchange", {
                state: { consentLiabality: location.state.consentLiabality },
              })
            }, 2500)
          })
          .catch(error => {
            console.log(error)
          })

        event.preventDefault()
      }
    } else {
      setDisabled(true)
      setShowLoadingData(true)
      postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      )
        .then(() => {
          window.setTimeout(() => {
            navigate("/app/medlemskap/applications/sendchange", {
              state: { consentLiabality: location.state.consentLiabality },
            })
          }, 2500)
        })
        .catch(error => {
          console.log(error)
        })

      event.preventDefault()
    }
  }

  return showLoadingData ? (
    // // "Laster inn..."
    <LoadingData subject="Oppretter..." />
  ) : (
    <div className="">
      <form
        method="POST"
        onSubmit={handleSubmit(postWork)}
        className="space-y-8 "
      >
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Rolle:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("role")}
                    name="role"
                    id="role"
                    key="role"
                    disabled={true}
                    className="bg-gray-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option value={125600000}>Hovedarbeidsgiver</option>
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="employer"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Arbeidsgiver:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    {...register("employer")}
                    name="employer"
                    id="employer"
                    key="employer"
                    disabled={disableE}
                    // defaultValue={defaultCountryCode}
                    // onChange={isNorwegian}
                    value={parent}
                    onChange={updateWorkPlace}
                    className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  >
                    <option disabled selected value="">
                      {" "}
                      -- Arbeidsgiver --{" "}
                    </option>
                    {company?.map(a => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                    {/* <option value="Annet">Annet</option> */}
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                <HelperText helpertext="Min arbeidsgiver står ikke i listen:" />
                <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                  <Switch
                    checked={enabled1}
                    onChange={() => showEmpAlt()}
                    className={classNames(
                      enabled1 ? "bg-meny-600" : "bg-gray-900",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled1 ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                </div>
              </div>

              {aGiverFri ? (
                <div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="employerAlternative"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsgivers navn:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register("employerAlternative", {
                          // pattern: {
                          //   value: /^[-A-Za-zÆØÅæøå ]+$/i,
                          //   message:
                          //     "Kun bokstaver og bindestrek er tillatt.",
                          // },
                          minLength: {
                            value: 2,
                            message: "Minimum tegn er 2",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maks tegn er 100",
                          },
                        })}
                        name="employerAlternative"
                        id="employerAlternative"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="employerAlternative"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workTelephone"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsgivers telefon:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register("workTelephone", {
                          pattern: {
                            value:
                              /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
                            message: "Skriv inn et gyldig telefonnummer.",
                          },
                        })}
                        name="workTelephone"
                        id="workTelephone"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="workTelephone"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="employerAlternativeAddress"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsgivers adresse:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        rows={3}
                        id="employerAlternativeAddress"
                        {...register("employerAlternativeAddress", {
                          maxLength: {
                            value: 850,
                            message: "Maks tegn er 850",
                          },
                        })}
                        // required
                        name="employerAlternativeAddress"
                        className="form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="employerAlternativeAddress"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {hideCheckboxPlace ? (
                <></>
              ) : (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlace"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidssted:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("workPlace")}
                        name="workPlace"
                        id="workPlace"
                        key="workPlace"
                        disabled={disableP}
                        value={selectedWrokLocation}
                        // onChange={isNorwegian}
                        onChange={updateEmployer}
                        className=" block w-full shadow-sm focus:ring-meny-500 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option disabled selected value={EmptyGuid}>
                          {" "}
                          -- Arbeidssted --{" "}
                        </option>

                        {places?.map(a => (
                          <option key={a.id} value={a.id}>
                            {a.name}
                          </option>
                        ))}
                        {/* <option value="Annet">Annet</option> */}
                      </select>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                    <HelperText helpertext="Mitt arbeidssted står ikke i listen:" />
                    <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                      <Switch
                        checked={enabled2}
                        onChange={() => showPlaceAlt()}
                        className={classNames(
                          enabled2 ? "bg-meny-600" : "bg-gray-900",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            enabled2 ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </>
              )}
              {AStedFri ? (
                <div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlaceAlternative"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsstedets navn:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register("workPlaceAlternative", {
                          // pattern: {
                          //   value: /^[-A-Za-zÆØÅæøå ]+$/i,
                          //   message:
                          //     "Kun bokstaver og bindestrek er tillatt.",
                          // },
                          minLength: {
                            value: 2,
                            message: "Minimum tegn er 2",
                          },
                          maxLength: {
                            value: 100,
                            message: "Maks tegn er 100",
                          },
                        })}
                        name="workPlaceAlternative"
                        id="workPlaceAlternative"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="workPlaceAlternative"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlaceTelephone"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsstedets telefon:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        type="text"
                        {...register("workPlaceTelephone", {
                          pattern: {
                            value:
                              /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
                            message: "Skriv inn et gyldig telefonnummer.",
                          },
                        })}
                        name="workPlaceTelephone"
                        id="workPlaceTelephone"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="workPlaceTelephone"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="workPlaceAlternativeAddress"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Arbeidsstedets adresse:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        rows={3}
                        id="workPlaceAlternativeAddress"
                        {...register("workPlaceAlternativeAddress", {
                          maxLength: {
                            value: 850,
                            message: "Maks tegn er 850",
                          },
                        })}
                        // required
                        name="workPlaceAlternativeAddress"
                        className="form-control h-20  block w-full text-base  px-3 py-1.5 shadow-sm focus:ring-meny-700 sm:text-sm focus:border-meny-500 border-gray-300 rounded-md transition ease-in-out m-0"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="workPlaceAlternativeAddress"
                        render={({ message }) => (
                          <p className="text-sm text-red-600">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {showPos ? (
                <div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                    <label
                      htmlFor="position"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Stilling:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("position")}
                        name="position"
                        id="position"
                        key="position"
                        value={position}
                        onChange={changePos}
                        disabled={disablePos}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option disabled selected value="">
                          {" "}
                          -- Stilling --{" "}
                        </option>

                        {positions?.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.jobTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
                    <HelperText helpertext="Min stilling står ikke i listen:" />
                    <div className="mt-1 sm:mt-0 sm:col-span-1 sm:col-start-6">
                      <Switch
                        checked={enabled3}
                        onChange={() => showPosAlt()}
                        className={classNames(
                          enabled3 ? "bg-meny-600" : "bg-gray-900",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            enabled3 ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {isPAlt ? (
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="workPlaceAlternative"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Stilling (fritekst):
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      {...register("positionAlternative", {
                        pattern: {
                          value: /^[-A-Za-zÆØÅæøå ]+$/i,
                          message: "Kun bokstaver og bindestrek er tillatt.",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum tegn er 2",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maks tegn er 100",
                        },
                      })}
                      name="positionAlternative"
                      id="positionAlternative"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="positionAlternative"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="partTimePosition"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Prosentvis stiling:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    {...register("partTimePosition", {
                      pattern: {
                        value: /^[1-9][0-9]?$|^100$/i,
                        message: "Kun tall mellom 1-100 er tillatt",
                      },
                    })}
                    name="partTimePosition"
                    placeholder="1-100"
                    defaultValue={100}
                    id="partTimePosition"
                    className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="partTimePosition"
                    render={({ message }) => (
                      <p className="text-sm text-red-600">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Start dato:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Controller
                    control={control}
                    name="startDate"
                    defaultValue={new Date()}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ReactDatePicker
                        id="startDate"
                        locale="nb"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="dd/MM/yyyy"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Slutt dato:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ReactDatePicker
                        locale="nb"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="dd/MM/yyyy"
                        className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      />
                    )}
                  />
                  {/* <input type="submit" /> */}
                </div>
              </div>
            </div>
            <div className="pt-5 flex justify-end">
              <button
                type="submit"
                disabled={disableButton}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
              >
                Neste
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="mb-2 flex justify-center">
        {invalidDate ? (
          <p className="text-red-500">
            Start dato kan ikke være etter slutt dato.
          </p>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

export default NewEmploymentToOrdinaryContent
