import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Employment } from "../../../Utils/Entities"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import { loginRequest } from "../../security/authConfig"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { Loading } from "../../../Utils/Loading"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { useQueryClient } from "react-query"
import LoadingData from "../../common/graphichs/LoadingData"
import { RadioGroupComponent } from "../../common/radioGroup/RadioGroup"
import { employerTypes } from "../../../Utils/optionSet"
import { NewEmploymentAsEmployee } from "./NewEmploymentAsEmployee"
import { NewEmploymentAsIndependent } from "./NewEmploymentAsIndependent"
registerLocale("nb", nb)

type FormValues = Employment

const NewEmploymentContent = ({
  roleOption,
  prevEmploymentData,
  professionallyActiveStatus,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>()

  const queryClient = useQueryClient()

  const [showLoadingData, setShowLoadingData] = useState(false)

  const [employerType, setEmployerType] = useState<number>(0)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  console.log(roleOption)
  console.log(prevEmploymentData)
  console.log(professionallyActiveStatus)

  return showLoadingData ? (
    // // "Laster inn..."
    <LoadingData subject="Oppretter..." />
  ) : (
    <MyPageLayout2 props="Registrere nytt arbeidsforhold">
      {/* {roleOption === 125600000 ? ( */}
      <div className="max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8 flex justify-center">
        <div className="max-w-lg">
          <RadioGroupComponent
            label={"Velg et arbeidsforhold"}
            value={employerType}
            onChange={setEmployerType}
            type={employerTypes}
          />
        </div>
      </div>
      {/* ) : null} */}

      <>
        {employerType === 292460000 && (
          <NewEmploymentAsEmployee
            roleOption={roleOption}
            prevEmploymentData={prevEmploymentData}
            setShowLoadingData={setShowLoadingData}
            professionallyActiveStatus={professionallyActiveStatus}
          />
        )}
        {employerType === 292460001 && (
          <NewEmploymentAsIndependent
            roleOption={roleOption}
            prevEmploymentData={prevEmploymentData}
            setShowLoadingData={setShowLoadingData}
            professionallyActiveStatus={professionallyActiveStatus}
          />
        )}
      </>
    </MyPageLayout2>
  )
}

const NewEmployment = ({ location }) => {
  const authRequest = {
    ...loginRequest,
  }
  const roleOption = location?.state?.role
  const professionallyActiveStatus = location?.state?.professionallyActiveStatus
  const prevEmploymentData = location?.state?.prevEmploymentData
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <NewEmploymentContent
        roleOption={roleOption}
        professionallyActiveStatus={professionallyActiveStatus}
        prevEmploymentData={prevEmploymentData}
      />
    </MsalAuthenticationTemplate>
  )
}

export default NewEmployment
