import { ErrorMessage } from "@hookform/error-message"
import React, { useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { Account } from "../../../../../Utils/Entities"
import useApi from "../../../../hooks/useApi"
import { FormValues } from "./FlowToOrdinaryTypes"

const ConvertEmployment = (props: {
  index: number
  formController: UseFormReturn<FormValues, any>
}) => {
  const [positions, setPositions] = useState<any[]>()

  const {
    register,
    formState: { errors },
  } = props.formController

  const id = props.formController.getValues(
    `employments.${props.index}.employer`
  )
  const data = useApi<Account>(`/Accounts/${id}`)

  useEffect(() => {
    if (data.isSuccess) {
      //@ts-ignore
      setPositions(data.data.tariff.positions)
    }
  }, [data])

  return (
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
        <label
          htmlFor={`employments.${props.index}.position`}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Stilling:
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <select
            {...register(`employments.${props.index}.position`, {
              required: { value: true, message: "Stilling er påkrevd" },
            })}
            name={`employments.${props.index}.position`}
            id={`employments.${props.index}.position`}
            key={`employments.${props.index}.position`}
            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
          >
            <option disabled selected value="">
              {" "}
              -- Stilling --{" "}
            </option>

            {positions?.map(p => (
              <option key={p.id} value={p.id}>
                {p.jobTitle}
              </option>
            ))}
          </select>
          <ErrorMessage
            errors={errors}
            name={`employments.${props.index}.position`}
            render={({ message }) => (
              <p className="text-sm text-red-600">{message}</p>
            )}
          />
        </div>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
        <label
          htmlFor={`employments.${props.index}.partTimePosition`}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Prosentvis stiling:
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <input
            type="text"
            {...register(`employments.${props.index}.partTimePosition`, {
              pattern: {
                value: /^[1-9][0-9]?$|^100$/i,
                message: "Kun tall mellom 1-100 er tillatt",
              },
            })}
            name={`employments.${props.index}.partTimePosition`}
            placeholder="1-100"
            id={`employments.${props.index}.partTimePosition`}
            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
          />
          <ErrorMessage
            errors={errors}
            name={`employments.${props.index}.partTimePosition`}
            render={({ message }) => (
              <p className="text-sm text-red-600">{message}</p>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default ConvertEmployment
